<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item v-if="isEdit">
          編輯 {{ helper.displayI18nText($i18n.locale, course.title) }}
        </el-breadcrumb-item>
        <el-breadcrumb-item v-else>
          新增課程
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div>
      <h1 v-if="isEdit">Edit Course 編輯課程資料</h1>
      <h1 v-else>新增課程資料</h1>
      <hr />
    </div>
    <div>
      <el-form
        ref="course"
        :model="course"
        @submit.prevent.native="submit"
        :validate-on-rule-change="false"
        label-position="right"
        label-width="200px"
      >
        <el-form-item
          label="標題 (EN)"
          :rules="[commonRules.input]"
          :prop="'title.en'"
        >
          <el-input v-model="course.title.en" required></el-input>
        </el-form-item>
        <el-form-item
          label="標題 (TW)"
          :rules="[commonRules.input]"
          :prop="'title.tw'"
        >
          <el-input v-model="course.title.tw"></el-input>
        </el-form-item>
        <el-form-item
          label="標題 (CN)"
          :rules="[commonRules.input]"
          :prop="'title.cn'"
        >
          <el-input v-model="course.title.cn"></el-input>
        </el-form-item>

        <el-form-item required label="是否為進行中的課程">
          <el-radio-group v-model="course.is_visible">
            <el-radio-button :label="1">是</el-radio-button>
            <el-radio-button :label="0">否</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item required label="是否顯示在官網的課程清單">
          <el-radio-group v-model="course.show_index">
            <el-radio-button :label="1">是</el-radio-button>
            <el-radio-button :label="0">否</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="課程封面圖" required class="avatar">
          <el-upload
            class="avatar-uploader"
            :action="postUrl"
            :headers="token"
            name="cover_photo"
            :show-file-list="false"
            :before-upload="() => {}"
            :on-success="handleAvatarSuccess"
            :on-remove="() => {}"
          >
            <img
              referrer="no-referrer|origin|unsafe-url"
              v-if="course.cover_img_url"
              :src="course.cover_img_url"
              class="ivyway-cover-photo"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="el-upload__tip" style="line-height: 10px;" slot="tip">圖片比例為3:2</div>
          </el-upload>
        </el-form-item>

        <el-form-item
          label="費用 (TWD)"
          :rules="[commonRules.input]"
          prop="fee"
        >
          <el-input-number v-model.number="course.fee"></el-input-number>
        </el-form-item>

        <el-form-item label="預設分班考">
          <el-select
            v-model="course.entry_test_paper_id"
            placeholder="預設分班考"
          >
            <el-option
              v-for="testPaper in testPapers"
              :key="testPaper.id"
              :label="testPaper.test_full_name"
              :value="testPaper.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="封面標籤">
          <el-tabs type="border-card">
            <el-tab-pane label="封面標籤(EN)">
              <el-input
                type="textarea"
                v-model="course.cover_tag.en"
              ></el-input>
            </el-tab-pane>
            <el-tab-pane label="封面標籤(TW)">
              <el-input
                type="textarea"
                v-model="course.cover_tag.tw"
              ></el-input>
            </el-tab-pane>
            <el-tab-pane label="封面標籤(CN)">
              <el-input
                type="textarea"
                v-model="course.cover_tag.cn"
              ></el-input>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="上課時長">
          <el-tabs type="border-card">
            <el-tab-pane label="上課時長(EN)">
              <el-input type="textarea" v-model="course.length.en"></el-input>
            </el-tab-pane>
            <el-tab-pane label="上課時長(TW)">
              <el-input type="textarea" v-model="course.length.tw"></el-input>
            </el-tab-pane>
            <el-tab-pane label="上課時長(CN)">
              <el-input type="textarea" v-model="course.length.cn"></el-input>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="簡介">
          <el-tabs type="border-card">
            <el-tab-pane label="簡介(EN)">
              <el-input type="textarea" v-model="course.summary.en"></el-input>
            </el-tab-pane>
            <el-tab-pane label="簡介(TW)">
              <el-input type="textarea" v-model="course.summary.tw"></el-input>
            </el-tab-pane>
            <el-tab-pane label="簡介(CN)">
              <el-input type="textarea" v-model="course.summary.cn"></el-input>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="內容">
          <el-tabs type="border-card">
            <el-tab-pane label="內容(EN)">
              <Ckeditor
                :value="course.content.en"
                @input="
                  content => {
                    course = {
                      ...course,
                      content: { ...course.content, en: content }
                    };
                  }
                "
              />
            </el-tab-pane>
            <el-tab-pane label="內容(TW)">
              <Ckeditor
                :value="course.content.tw"
                @input="
                  content => {
                    course = {
                      ...course,
                      content: { ...course.content, tw: content }
                    };
                  }
                "
              />
            </el-tab-pane>
            <el-tab-pane label="內容(CN)">
              <Ckeditor
                :value="course.content.cn"
                @input="
                  content => {
                    course = {
                      ...course,
                      content: { ...course.content, cn: content }
                    };
                  }
                "
              />
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="班級人數">
          <el-tabs type="border-card">
            <el-tab-pane label="班級人數(EN)">
              <el-input
                type="textarea"
                v-model="course.class_size.en"
              ></el-input>
            </el-tab-pane>
            <el-tab-pane label="班級人數(TW)">
              <el-input
                type="textarea"
                v-model="course.class_size.tw"
              ></el-input>
            </el-tab-pane>
            <el-tab-pane label="班級人數(CN)">
              <el-input
                type="textarea"
                v-model="course.class_size.cn"
              ></el-input>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="上課語言">
          <el-tabs type="border-card">
            <el-tab-pane label="上課語言(EN)">
              <el-input type="textarea" v-model="course.lang.en"></el-input>
            </el-tab-pane>
            <el-tab-pane label="上課語言(TW)">
              <el-input type="textarea" v-model="course.lang.tw"></el-input>
            </el-tab-pane>
            <el-tab-pane label="上課語言(CN)">
              <el-input type="textarea" v-model="course.lang.cn"></el-input>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="程度">
          <el-tabs type="border-card">
            <el-tab-pane label="程度(EN)">
              <el-input type="textarea" v-model="course.level.en"></el-input>
            </el-tab-pane>
            <el-tab-pane label="程度(TW)">
              <el-input type="textarea" v-model="course.level.tw"></el-input>
            </el-tab-pane>
            <el-tab-pane label="程度(CN)">
              <el-input type="textarea" v-model="course.level.cn"></el-input>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="程度要求">
          <el-tabs type="border-card">
            <el-tab-pane label="程度要求(EN)">
              <el-input
                type="textarea"
                v-model="course.prerequisite.en"
              ></el-input>
            </el-tab-pane>
            <el-tab-pane label="程度要求(TW)">
              <el-input
                type="textarea"
                v-model="course.prerequisite.tw"
              ></el-input>
            </el-tab-pane>
            <el-tab-pane label="程度要求(CN)">
              <el-input
                type="textarea"
                v-model="course.prerequisite.cn"
              ></el-input>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="功課量">
          <el-tabs type="border-card">
            <el-tab-pane label="功課量(EN)">
              <el-input
                type="textarea"
                v-model="course.homework_workload.en"
              ></el-input>
            </el-tab-pane>
            <el-tab-pane label="功課量(TW)">
              <el-input
                type="textarea"
                v-model="course.homework_workload.tw"
              ></el-input>
            </el-tab-pane>
            <el-tab-pane label="功課量(CN)">
              <el-input
                type="textarea"
                v-model="course.homework_workload.cn"
              ></el-input>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="費用說明">
          <el-tabs type="border-card">
            <el-tab-pane label="費用說明(EN)">
              <el-input
                type="textarea"
                v-model="course.fee_description.en"
              ></el-input>
            </el-tab-pane>
            <el-tab-pane label="費用說明(TW)">
              <el-input
                type="textarea"
                v-model="course.fee_description.tw"
              ></el-input>
            </el-tab-pane>
            <el-tab-pane label="費用說明(CN)">
              <el-input
                type="textarea"
                v-model="course.fee_description.cn"
              ></el-input>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="上課教材">
          <el-tabs type="border-card">
            <el-tab-pane label="上課教材(EN)">
              <el-input
                type="textarea"
                v-model="course.materials.en"
              ></el-input>
            </el-tab-pane>
            <el-tab-pane label="上課教材(TW)">
              <el-input
                type="textarea"
                v-model="course.materials.tw"
              ></el-input>
            </el-tab-pane>
            <el-tab-pane label="上課教材(CN)">
              <el-input
                type="textarea"
                v-model="course.materials.cn"
              ></el-input>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
        <el-form-item>
          <div class="text-center">
            <el-button type="primary" @click="onSubmit">儲存</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { helper } from "@ivy-way/material";
import Ckeditor from "@/components/ckeditor/index.vue";
import courseApi from "@/apis/course";
import formMixin from "@/mixins/form";
import enrollmentApi from "@/apis/enrollment";
import JwtService from "@/common/jwt.service";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  metaInfo() {
    return {
      title: "Create Course Introduction - Ivy-Way Academy"
    };
  },

  components: {
    Breadcrumb,
    Ckeditor
  },

  mixins: [formMixin],

  props: [],
  data() {
    return {
      token: { Authorization: `Bearer ${JwtService.getToken()}` },
      course: {
        is_visible: 1,
        show_index: 1,
        title: { en: "", tw: "", cn: "" },
        cover_tag: { en: "", tw: "", cn: "" },
        length: { en: "", tw: "", cn: "" },
        summary: { en: "", tw: "", cn: "" },
        content: { en: "", tw: "", cn: "" },
        class_size: { en: "", tw: "", cn: "" },
        level: { en: "", tw: "", cn: "" },
        lang: { en: "", tw: "", cn: "" },
        prerequisite: { en: "", tw: "", cn: "" },
        homework_workload: { en: "", tw: "", cn: "" },
        materials: { en: "", tw: "", cn: "" },
        fee_description: { en: "", tw: "", cn: "" },
        page_url: ""
      },
      testPapers: []
    };
  },
  computed: {
    helper() {
      return helper;
    },
    postUrl() {
      return process.env.VUE_APP_BACKEND_URL + "upload_cover";
    },
    isEdit() {
      if (this.$route.params.id) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {},

  async mounted() {
    if (this.$route.params.id) {
      let courseRes = await courseApi.fetchCourse(this.$route.params.id);
      this.course = this.buildTranslatedFields(courseRes.course);
    }
    let testPapersRes = await enrollmentApi.fetchTestPapers();
    this.testPapers = testPapersRes.test_papers;
  },

  methods: {
    buildTranslatedFields(course) {
      if (course.title == null) {
        course.title = {};
      }
      if (course.length == null) {
        course.length = {};
      }
      if (course.summary == null) {
        course.summary = {};
      }
      if (course.content == null) {
        course.content = {};
      }
      if (course.class_size == null) {
        course.class_size = {};
      }
      if (course.level == null) {
        course.level = {};
      }
      if (course.lang == null) {
        course.lang = {};
      }
      if (course.prerequisite == null) {
        course.prerequisite = {};
      }
      if (course.homework_workload == null) {
        course.homework_workload = {};
      }
      if (course.materials == null) {
        course.materials = {};
      }
      if (course.fee_description == null) {
        course.fee_description = {};
      }

      return course;
    },
    handleAvatarSuccess(res) {
      this.course.cover_img_url = res.cover_photo_url;
    },
    async onSubmit(){
      const valid = await this.validateForm(this.$refs["course"]);
      if (!valid) {
        this.$message.error(this.$t("message.form_validation_error"));
        return;
      }
      if(this.isEdit){
        this.onSubmitUpdate();
      } else {
        this.onSubmitCreate();
      }
    },
    async onSubmitCreate() {
      await courseApi.createCourse(this.course).then(response => {
        this.$message({
          showClose: true,
          message: this.$t("message.create_success"),
          type: "success"
        });
        this.routerPush({
          name: "courseIntrosList"
        }).catch(error => {
          this.$message({
            showClose: true,
            message: this.$t("message.something_went_wrong"),
            type: "error"
          });
        });
      });
    },
    async onSubmitUpdate() {
      await courseApi.postCourse(this.course.id, this.course).then(response => {
        this.$message({
          showClose: true,
          message: this.$t("message.update_success"),
          type: "success"
        });
        this.routerPush({
          name: "courseIntrosList"
        }).catch(error => {
          this.$message({
            showClose: true,
            message: this.$t("message.something_went_wrong"),
            type: "error"
          });
        });
      });
    }
  }
};
</script>

<style scoped>
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
::v-deep .el-upload{
  display: block;
  height: 200px;
  width: 300px;
}
.avatar .ivyway-cover-photo {
  /* max-width: 200px; */
  display: block;
  width: 100%;
  height: 100%;
}
</style>
